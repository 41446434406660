import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Privacy() {
  return (
   <>
   <Header/>
   <main class="main">
        <section>
            <div class="container">
                <div class="wrapper-policy">
                    <h1>Privacy Policy for Rezervatix</h1>

                    <p>At canadasocialgame.com, accessible from https://canadasocialgame.com, one of our
                        main
                        priorities is the privacy of our visitors. This Privacy Policy document contains types of
                        information that is collected and recorded by canadasocialgame.com and how we use it.</p>

                    <p>If you have additional questions or require more information about our Privacy Policy, do not
                        hesitate to contact us.</p>

                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our
                        website with regards to the information that they shared and/or collect in
                        canadasocialgame.com. This policy is not applicable to any information collected offline
                        or
                        via channels other than this website.</p>

                    <h2>Consent</h2>

                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                    <h2>Information we collect</h2>

                    <p>The personal information that you are asked to provide, and the reasons why you are asked to
                        provide it, will be made clear to you at the point we ask you to provide your personal
                        information.</p>

                    <p>If you contact us directly, we may receive additional information about you such as your name,
                        email address, phone number, the contents of the message and/or attachments you may send us, and
                        any other information you may choose to provide.</p>

                    <p>When you register for an Account, we may ask for your contact information, including items such
                        as name, company name, address, email address, and telephone number.</p>

                    <h2>How we use your information</h2>

                    <p>We use the information we collect in various ways, including to:</p>

                    <ul>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer
                            service, to provide you with updates and other information relating to the website, and for
                            marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>

                    <h2>Log Files</h2>

                    <p>canadasocialgame.com follows a standard procedure of using log files. These files log
                        visitors
                        when they visit websites. All hosting companies do this and a part of hosting services'
                        analytics. The information collected by log files include internet protocol (IP) addresses,
                        browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
                        possibly the number of clicks. These are not linked to any information that is personally
                        identifiable. The purpose of the information is for analyzing trends, administering the site,
                        tracking users' movement on the website, and gathering demographic information.</p>
                    <h2>Cookies and Web Beacons</h2>
                    <p>Like any other website, canadasocialgame.com uses 'cookies'. These cookies are used to
                        store
                        information including visitors' preferences, and the pages on the website that the visitor
                        accessed or visited. The information is used to optimize the users' experience by customizing
                        our web page content based on visitors' browser type and/or other information.</p>
                    <h2>Our Advertising Partners</h2>

                    <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are
                        listed below. Each of our advertising partners has their own Privacy Policy for their policies
                        on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

                    <h3>Google</h3>
                    <a href="https://policies.google.com/technologies/ads" target="_blank">https://policies.google.com</a>

                    <h2>Advertising Partners Privacy Policies</h2>

                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of
                        canadasocialgame.com.</p>
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
                        that are used in their respective advertisements and links that appear on
                        canadasocialgame.com, which are sent directly to users' browser. They automatically
                        receive
                        your IP address when this occurs. These technologies are used to measure the effectiveness of
                        their advertising campaigns and/or to personalize the advertising content that you see on
                        websites that you visit.</p>
                    <p>Note that canadasocialgame.com has no access to or control over these cookies that are used
                        by
                        third-party advertisers.</p>
                    <h2>Third Party Privacy Policies</h2>
                    <p>canadasocialgame.com's Privacy Policy does not apply to other advertisers or websites.
                        Thus, we
                        are advising you to consult the respective Privacy Policies of these third-party ad servers for
                        more detailed information. It may include their practices and instructions about how to opt-out
                        of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. For more detailed
                        information about cookie management with specific web browsers, visit the browsers' respective
                        websites.</p>

                    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                    <p>Under the CCPA, California consumers have the right to:</p>

                    <ul>
                        <li>Request disclosure of categories and specific pieces of personal data collected.</li>
                        <li>Request deletion of personal data collected.</li>
                        <li>Opt-out of the sale of personal data.</li>
                    </ul>

                    <p>If you wish to exercise these rights, please contact us. We have one month to respond to your
                        request.</p>

                    <h2>GDPR Data Protection Rights</h2>

                    <p>Every user is entitled to the following GDPR rights:</p>

                    <ul>
                        <li>The right to access – Request copies of your personal data. A small fee may apply.</li>
                        <li>The right to rectification – Request correction of inaccurate or incomplete information.
                        </li>
                        <li>The right to erasure – Request erasure of your personal data under certain conditions.</li>
                        <li>The right to restrict processing – Request restrictions on processing your personal data
                            under certain conditions.</li>
                        <li>The right to object to processing – Object to our processing of your personal data under
                            certain conditions.</li>
                        <li>The right to data portability – Request transfer of your data to another organization or
                            directly to you under certain conditions.</li>
                    </ul>

                    <p>If you wish to exercise these rights, please contact us. We have one month to respond to your
                        request.</p>

                    <h2>Children's Information</h2>

                    <p>Protecting children online is a priority. Parents and guardians are encouraged to observe,
                        participate in, and/or monitor and guide their children's online activity.</p>

                    <p>canadasocialgame.com does not knowingly collect any Personal Identifiable Information from
                        children under the age of 13. If your child provided such information on our website, please
                        contact us immediately, and we will make efforts to promptly remove it from our records.</p>
                </div>
            </div>
        </section>
    </main>
   <Footer/>
   </>
  )
}

export default Privacy